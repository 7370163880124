import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommondataService {

  url:string='';
  couponCode:string = '';
  mobileNumber:string = '';
  name:string = '';
  ipConfig:string = '';
  language:string = '';

  constructor() { }
}
